import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "gatsby";
import Logo from "../images/dentlink_logo.svg";

export default function PageHeader() {
  const handleCTA = React.useCallback(() => {
    window.location.href = "https://www.dentlink.app";
  }, []);

  return (
    <Header>
      <div>
        <h1
          css={css`
            cursor: pointer;
          `}
        >
          <Link to="/">
            <img
              src={Logo}
              alt="dentlink.app"
              css={css`
                width: 160px;
                margin-top: 10px;
              `}
            />
          </Link>
        </h1>
        <Ctabutton type="button" onClick={handleCTA}>
          지금 사용하기
        </Ctabutton>
      </div>
    </Header>
  );
}

const Header = styled.header`
  width: 100%;
  height: 116px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: #fff;
  position: fixed;
  top: 0px;
  > div {
    width: 1024px;
    max-width: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Ctabutton = styled.button`
  width: 160px;
  height: 42px;
  font-size: 16px;
  background-color: #3751ff;
  color: #fff;
  border-radius: 11px;
  text-align: center;
  border: none;
  cursor: pointer;

  transition: all 0.15s ease-in-out;
  padding: 5px 0px 3px 0px;

  &:hover {
    box-shadow: 0 0 20px 0 #3751ff80;
  }

  @media screen and (max-width: 420px) {
    width: 100px;
    font-size: 14px;
  }
`;
