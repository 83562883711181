import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import innovaidLogo from "../images/innovaid.png";

function Footer() {
  return (
    <Wrap>
      <div>
        <div css={FlexBetween}>
          <img
            src={innovaidLogo}
            alt="팀 이노바이드 로고"
            css={css`
              width: 200px;
            `}
          />
          <div css={TermInfoStyle}>
            <Link to="/terms/service">서비스이용약관</Link>
            <Link to="/terms/privacy">개인정보처리방침</Link>
          </div>
        </div>
        <StyledHR />
        <div css={CompanyInfoStyle}>
          <span>주식회사 이노바이드(Innovaid)</span>
          <p>
            대표이사 : 국진혁 | 개인정보 관리책임자 : 국진혁
            <br />
            서울특별시 관악구 관악로 158, 3층
            <br />
            사업자등록번호 : 304-18-18799
          </p>
        </div>
      </div>
    </Wrap>
  );
}

export default Footer;

const Wrap = styled.footer`
  height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #495057;
  color: white;

  > div {
    width: 1024px;
    max-width: 100%;
    padding: 20px;
  }

  hr {
    margin: 50px 0px;
  }
`;

const TermInfoStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
  a {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }
  @media screen and (max-width: 678px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const CompanyInfoStyle = css`
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  text-align: left;
  line-height: 24px;
  height: auto;

  span {
    display: inline-block;
    margin-bottom: 20px;
  }
`;

const StyledHR = styled.hr`
  width: 100%;
  height: 2px;
  border: 0;
  background-color: #fff;
`;

const FlexBetween = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 678px) {
    flex-direction: column;
    gap: 40px;
  }
`;
