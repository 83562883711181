import React from "react";
import { css, Global } from "@emotion/react";
import styled from "@emotion/styled";
import PageHeader from "./Header";
import Footer from "./Footer";

export default function Layout({ children }) {
  return (
    <Main>
      <Global styles={GlobalStyle} />
      <PageHeader />
      {children}
      <Footer />
    </Main>
  );
}

const GlobalStyle = css`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  font-size: 16px;
`;

const Main = styled.main`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-top: 116px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  @media screen and (max-width: 378px) {
    font-size: 14px;
  }
`;
